import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="moods"
export default class extends Controller {
  static targets = ["hiddenField"];

  connect() {
    console.log("Moods controller connected");
  }

  selectMood(event) {
    const statusId = event.currentTarget.dataset.statusId; // Get the status ID
    this.hiddenFieldTarget.value = statusId; // Set the hidden field's value
    console.log(`Selected mood with ID: ${statusId}`);
  }

  hiddenFieldTargetConnected(element) {
    console.log(element)
  }
}
