import { Controller } from "@hotwired/stimulus"
import {Spinner} from 'spin.js';

// Connects to data-controller="spin"
export default class extends Controller {
  static targets = ["spinContainer"]

  connect() {
    super.connect()
    this.element.addEventListener("spin:start", () => this.start());
    this.element.addEventListener("spin:stop", () => this.stopSpinner());
  }

  start(event) {
    this.spinnerValue = new Spinner({color:'#333', lines: 9, scale: 0.7}).spin(this.spinContainerTarget)
    let self = this

    document.addEventListener("turbo:before-fetch-response", function (e) {
      self.stopSpinner()
    })
  }

  stopSpinner() {
    if (this.spinnerValue) {
      this.spinnerValue.stop()
      this.spinnerValue = null
    }
  }

  disconnect() {
    super.disconnect();
    this.element.removeEventListener("spin:start", () => this.start());
    this.element.removeEventListener("spin:stop", () => this.stopSpinner());
    this.stopSpinner()
  }
}
