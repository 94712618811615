import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = { org: String, routes: Array } // Define values for org and routes

    connect() {
        this.toggleVisibility()
    }

    toggleVisibility() {
        const org = this.orgValue
        const currentRoute = window.location.pathname
        const hideOnRoutes = ['/order/offers']

        if (org === "Shred" && hideOnRoutes.includes(currentRoute)) {
            this.element.classList.remove("sm:flex") // this class overrides display: none
            this.element.classList.add("hidden")
        } else {
            this.element.classList.remove("hidden")
            this.element.classList.add("sm:flex")
        }
    }
}