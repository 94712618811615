import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { spinTarget: String };

  connect() {
    this.observer = new IntersectionObserver(this.handleIntersect.bind(this), {
      threshold: 1,
    });

    this.observer.observe(this.element);
  }

  handleIntersect(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.startSpinner();
        this.loadMore();
        this.observer.unobserve(entry.target); // Stop observing after triggering once
      }
    });
  }

  async loadMore() {
    const url = this.element.href;
    const turboMethod = this.element.dataset.turboMethod || "get";

    if (turboMethod.toLowerCase() === "post") {
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Accept": "text/vnd.turbo-stream.html",
            "Content-Type": "application/x-www-form-urlencoded",
            "X-CSRF-Token": this.getCSRFToken(),
          },
          body: new URLSearchParams({ authenticity_token: this.getCSRFToken() })
        });

        const turboHtml = await response.text();
        Turbo.renderStreamMessage(turboHtml);
        this.stopSpinner();
      } catch (error) {
        console.error("Turbo fetch error:", error);
        this.stopSpinner();
      }
    } else {
      Turbo.visit(url);
    }
  }

  startSpinner() {
    this.element.dispatchEvent(new Event("spin:start", { bubbles: true }));
  }

  stopSpinner() {
    this.element.dispatchEvent(new Event("spin:stop", { bubbles: true }));
  }

  getCSRFToken() {
    return document.querySelector("meta[name='csrf-token']")?.content || "";
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}