import { Controller } from "@hotwired/stimulus"
import { createConsumer } from "@rails/actioncable"
import debounce from "lodash/debounce";

// Connects to data-controller="workout-socket"
export default class extends Controller {
  static values = {uid: Number, wid: Number}

  connect() {
    console.log('workout socket connected')
    this.boundHandleVideoTimeEvent = debounce(this.handleVideoTimeEvent, 500).bind(this);
    window.addEventListener("video:time", this.boundHandleVideoTimeEvent);

    this.channel = createConsumer().subscriptions.create(
        { channel: "WorkoutChannel", workout_id: this.widValue, user_id: this.uidValue },
        {
          connected: this.connected.bind(this),
          disconnected: this.disconnected.bind(this),
          received: (data) => this.handleResponse(data) }
    );
  }


  handleResponse(data) {
    if(data.participants) {
      this.renderParticipant(data)
    }
    else if(data.toast_html){
      this.showNotification(data.toast_html);
    }
  }

  renderParticipant(data) {
    console.log(data)

    // Update the workout participant count
    const countElement = document.querySelector('.workout-count h2');
    if (countElement) {
      countElement.textContent = data.participants_count;
    }

    // Update the participants list
    const participantsContainer = document.querySelector('.workout-count .space-y-4');
    if (participantsContainer) {
      participantsContainer.innerHTML = ""
      // Loop through the new participant data
      data.participants.forEach(participant => {
          participantsContainer.insertAdjacentHTML('beforeend', `
            <div class="participant-item grid grid-cols-4 items-center gap-4" data-participant-id="${participant.id}">
              <div class="flex items-center space-x-4">
                ${
                  participant.image
                  ? `<img class="h-10 w-10 rounded-full" src="${participant.image}" alt="">`
                  : `<div class="h-10 w-10 rounded-full flex items-center justify-center bg-blue-100">
                      <span class="text-blue-700 font-semibold">${participant.name.charAt(0).toUpperCase()}</span>
                    </div>`
                }
                <div>
                  <div class="font-semibold">${participant.name}</div>
                  <div class="text-sm text-gray-500">${participant.ordinal_count}</div>
                </div>
              </div>

              <div class="flex items-center justify-start space-x-2">
              ${
                this.uidValue != participant.id
                ?
                `<div class="highfive-button cursor-pointer" data-action="click->workout-socket#sendHighFive" data-participant-id="${participant.id}">
                  <img src="${this.participantUrl('highfive.svg')}" class="w-6 h-6">
                </div>`
                : ``
              }
              </div>
            </div>
          `);
      });
    }
  }

  sendHighFive(event) {
    const participantId = event.currentTarget.dataset.participantId;
    this.channel.perform("high_five", { to: participantId });
  }

  showNotification(message) {
    // Create a div element to hold the notification
    const notificationElement = document.createElement('div');

    // Apply any desired classes (if necessary)
    notificationElement.classList.add('notification-wrapper', 'absolute', 'top-[70%]', 'left-1/2', 'transform', '-translate-x-1/2');

    // Set the innerHTML to the received message (which contains the HTML structure)
    notificationElement.innerHTML = message;

    // Append to video player container or wherever you want the notification
    const videoPlayerContainer = document.querySelector('.workout-video-player-show');
    videoPlayerContainer.appendChild(notificationElement);

    // Remove the notification after a few seconds
    setTimeout(() => {
      notificationElement.classList.add('animate-fade-out');
    }, 1500);
  }

  participantUrl(filename) {
    // Helper method to get the correct asset path in JavaScript
    return `/assets/${filename}`;
  }

  disconnect(event) {
    this.channel.unsubscribe();
  }

  handleVideoTimeEvent(event) {
    const { currentTime } = event.detail;
    this.sendVideoTime(currentTime);
  }

  sendVideoTime(currentTime) {
    if (this.channel) {
      this.channel.perform("update_vpi", { current_time: currentTime });
    }
  }

  connected() {
    console.log("Connected to WorkoutChannel");
  }

  disconnected() {
    console.log("Disconnected from WorkoutChannel");
  }

  received(data) {
    console.log("Received data:", data);
  }
}
