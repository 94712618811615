import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["typeDropdown", "workoutDropdown", "videoDropdown"];

  connect() {
    this.toggleItemDropdown();
  }

  toggleItemDropdown() {
    const selectedType = this.typeDropdownTarget.value;

    if (selectedType === "workout") {
      this.workoutDropdownTarget.style.display = "block";
      this.videoDropdownTarget.style.display = "none";
    } else if (selectedType === "video") {
      this.workoutDropdownTarget.style.display = "none";
      this.videoDropdownTarget.style.display = "block";
    }
  }
}