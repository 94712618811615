import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="calendar"
export default class extends Controller {
  static targets = ["calendar"]; // Defines the "calendar" target

  connect() {
    console.log("Calendar controller connected");
    console.log("Current month:", this.element.dataset.currentMonth);
  }

  previousMonth(event) {
    event.preventDefault();
    this.updateCalendar(-1); // Move one month back
  }

  nextMonth(event) {
    event.preventDefault();
    this.updateCalendar(1); // Move one month forward
  }

  moodpreviousMonth(event) {
    event.preventDefault();
    this.moodupdateCalendar(-1); // Move one month back
  }

  moodnextMonth(event) {
    event.preventDefault();
    this.moodupdateCalendar(1); // Move one month forward
  }

  updateCalendar(monthOffset) {
    // Get the current month and calculate the new month
    const currentMonth = new Date(this.element.dataset.currentMonth);
    const newMonth = new Date(currentMonth.setMonth(currentMonth.getMonth() + monthOffset));

    // Format the new month as YYYY-MM (required for the Rails route)
    const newMonthString = `${newMonth.getFullYear()}-${String(newMonth.getMonth() + 1).padStart(2, "0")}-01`;

    // Update the `data-current-month` attribute to reflect the new month
    this.element.dataset.currentMonth = newMonthString;

    // Send an AJAX request to fetch the updated calendar
    fetch(`/workout_stats/workout_history?month=${newMonthString}`, {
      headers: { "Accept": "text/vnd.turbo-stream.html" },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text();
      })
      .then((html) => {
        // Replace the calendar section with the new HTML
        this.calendarTarget.innerHTML = html;
      })
      .catch((error) => {
        console.error("Error updating calendar:", error);
      });
  }

  moodupdateCalendar(monthOffset) {
    // Get the current month and calculate the new month
    const currentMonth = new Date(this.element.dataset.currentMonth);
    const newMonth = new Date(currentMonth.setMonth(currentMonth.getMonth() + monthOffset));

    // Format the new month as YYYY-MM (required for the Rails route)
    const newMonthString = `${newMonth.getFullYear()}-${String(newMonth.getMonth() + 1).padStart(2, "0")}-01`;

    // Update the `data-current-month` attribute to reflect the new month
    this.element.dataset.currentMonth = newMonthString;

    // Send an AJAX request to fetch the updated calendar
    fetch(`/workout_stats/mood_history?month=${newMonthString}`, {
      headers: { "Accept": "text/vnd.turbo-stream.html" },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text();
      })
      .then((html) => {
        // Replace the calendar section with the new HTML
        this.calendarTarget.innerHTML = html;
      })
      .catch((error) => {
        console.error("Error updating calendar:", error);
      });
  }
}
